import styled from 'styled-components';
import tw from 'twin.macro';

export const Banner = styled.section`
  ${tw`bg-gray-100 border-b border-indigo-100`};
`;

export const Content = styled.p`
  ${tw`mb-8 w-full text-center`};
`;
